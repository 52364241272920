<script>
	import { t } from '@woltair/translations-package-fe'
	import ConditionalAnchor from '$lib/components/ConditionalAnchor.svelte'

	export let textWidth // (celá tailwind třída) šířka pravého kontejneru s textem, ovlivňuje velikost obrázku a zalomení textu
	export let subtextWidth = 'max-w-full' // (celá tailwind třída) šířka subtextu v rámci textového kontejneru – hlavně kvůli zalomení textu
	export let content //objekt s obsahem pro box, obsahuje header, text, buttonText, buttonUrl, imageUrl
	export let bgColor = 'bg-white' //(celá tailwind třída) barva pozadí boxu
	export let buttonClass = 'btn-filled-primary' //třída pro stylování buttonu
	export let textColor = 'text-neutral-800' //barva textu v celém boxu
	export let woltairW = false //Woltair logo jako background image v pravém dolním rohu?
	export let imageCls // obrázky v boxech jsou trochu tricky a někdy je potřeba je nastylovat individuálně, aby odpovídaly návrhu
</script>

<article
	class="flex min-h-[340px] p-8-res pb-10-res pr-2 {bgColor} flex-wrap overflow-hidden {content.imageUrl
		? 'justify-between'
		: 'justify-start'}"
	class:woltair-w={woltairW}
>
	{#if content.header}
		<ConditionalAnchor condition={content.headingAnchor} href={content.headingAnchor}>
			<h2
				class="unstyled xs:hidden mb-0 !text-4xl-res !font-extrabold leading-tight tracking-normal lg:hidden {textColor} lg:min-w-none w-11/12 min-w-[50vw]"
			>
				{@html t(content.header)}
			</h2>
		</ConditionalAnchor>
	{/if}
	<div class="text basis-2/3 {textWidth} z-10">
		{#if content.header}
			<ConditionalAnchor condition={content.headingAnchor} href={content.headingAnchor}>
				<h2
					class="unstyled xs:block mb-5 hidden !text-4xl-res !font-extrabold leading-tight tracking-normal lg:block {textColor}"
				>
					{@html t(content.header)}
				</h2>
			</ConditionalAnchor>
		{/if}
		{#if content.text}
			<p class="mb-8 text-lg-res leading-snug {textColor} max-w-[90%] lg:{subtextWidth}">
				{@html t(content.text)}
			</p>
		{/if}
		{#if content.buttonText}
			<a
				class="btn {buttonClass} text-base-res"
				href={content.buttonUrl}
				data-testid={content.buttonTestId}>{@html t(content.buttonText)}</a
			>
		{/if}
	</div>
	{#if content.imageUrl}
		<div class="relative flex max-w-[30vw] flex-shrink flex-grow items-start sm:max-w-none">
			<ConditionalAnchor condition={content.imageAnchor} href={content.imageAnchor}>
				<img
					src={content.imageUrl}
					alt={content.imageAlt}
					class="absolute max-h-full w-full object-contain {imageCls} md:my-unset inset-y-0 my-auto md:bottom-0 md:top-auto"
				/>
			</ConditionalAnchor>
		</div>
	{/if}
</article>

<style>
	article {
		box-shadow: 0 2px 16px 0 rgba(185, 185, 185, 0.5);
	}

	.woltair-w {
		background-image: url('$static/images/logos/woltair-w-outline.svg');
		background-size: 62% auto;
		background-repeat: no-repeat;
		background-position: right -65% bottom;
	}
</style>
